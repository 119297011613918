export const environment = {
  production: false,
  serverUrl: 'https://6dfshlbbc6.execute-api.us-east-2.amazonaws.com/dev',
  bucket: 'kcas-regressiondev-upload',
  userPool: {
    UserPoolId: 'us-east-2_h8CkNsvUA',
    ClientId: '7rpmol8m7kb9jagt9fdugbu4c5',
    region: 'us-east-2'
  },
  identityPoolId: 'us-east-2:87e7fc66-8b78-45ab-b1ea-c7077bb6ff6c',

  defaultRegion: 'us-east-2',
    cognito: {
      identityPoolId: 'us-east-2:87e7fc66-8b78-45ab-b1ea-c7077bb6ff6c', //REQUIRED - Amazon Cognito Identity Pool ID
      region: 'us-east-2', // REQUIRED - Amazon Cognito Region
      userPoolId: 'us-east-2_h8CkNsvUA', //OPTIONAL - Amazon Cognito User Pool ID
      userPoolWebClientId: '7rpmol8m7kb9jagt9fdugbu4c5', //OPTIONAL - Amazon Cognito Web Client ID
    },
    Storage: {
      AWSS3: {
        bucket: 'kcas-regressiondev-upload', //REQUIRED -  Amazon S3 bucket
        region: 'us-east-2', //OPTIONAL -  Amazon service region
      }
    }
};

const awsconfig = {
  'aws_project_region': 'us-east-2',
  'aws_cognito_region': 'us-east-2',
  'aws_user_pools_id': 'us-east-2_h8CkNsvUA',
  'aws_user_pools_web_client_id': '7rpmol8m7kb9jagt9fdugbu4c5',
  'aws_cognito_mfa_configuration': 'OPTIONAL',

};
export default awsconfig;



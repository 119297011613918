import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AnalysisKey, SearchResult} from '../../../model/analysis-details.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import { I } from '@angular/cdk/keycodes';


@Component({
  selector: 'app-show-table',
  templateUrl: './show-table.component.html',
  styleUrls: ['./show-table.component.scss']
})
export class ShowTableComponent implements OnInit {

  @ViewChild('RApaginator', {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  dataSource: MatTableDataSource<AnalysisKey>;
  tableColumns: string[] = ['analysisType', 'analysisSubtypeDisplay', 'analysisDate',  'projectNames', 'analyteName'];

  @ViewChild('CHpaginator', {static: false}) ch_paginator: MatPaginator;
  @ViewChild('CHsort', {static: false}) ch_sort: MatSort;
  chromatogramDS: MatTableDataSource<AnalysisKey>;
  chromatogramColumns: string[] = ['analysisType', 'analysisDate',  'analyteName'];

  @ViewChild('RSGpaginator', {static: false}) rsg_paginator: MatPaginator;
  @ViewChild('RSGsort', {static: false}) rsg_sort: MatSort;
  reportPopulationDS: MatTableDataSource<AnalysisKey>;
  reportPopulationColumns: string[] = ['analysisType', 'analysisDate', 'projectNames', 'analyteName'];

  reportAnalysis: AnalysisKey[] = [];
  chromatogramAnalysis: AnalysisKey[] = [];
  reportPopulation: AnalysisKey[] = [];

  @Input() searchResults: AnalysisKey[] = [];
  count: number;

  constructor() {}

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.searchResults);
    // console.log('this data source', this.dataSource);
    setTimeout(() => this.dataSource.paginator = this.paginator);
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'analysisDate': return new Date(item.analysisDate);
        // case 'analysisDate': return item.analysisDate;
        case 'projectNames': return item.projectNames ;
        case 'analyteNames': return item.analyteNames ;
        default: return item[property];
      }
    }
    setTimeout(() => this.dataSource.sort = this.sort);

    for (const result of this.searchResults) {
      if (result.analysisType === 'CHR') {
        this.chromatogramAnalysis.push(result);
      } else if (result.analysisType === 'RSG') {
        this.reportPopulation.push(result);
      } else {
        this.reportAnalysis.push(result);
      }
    }
    this.dataSource = new MatTableDataSource(this.reportAnalysis);
    setTimeout(() => this.dataSource.paginator = this.paginator);
    setTimeout(() => {this.dataSource.sort = this.sort});

    this.reportPopulationDS = new MatTableDataSource(this.reportPopulation);
    setTimeout(() => this.reportPopulationDS.paginator = this.rsg_paginator);
    setTimeout(() => {this.reportPopulationDS.sort = this.rsg_sort});

    this.chromatogramDS = new MatTableDataSource(this.chromatogramAnalysis);
    setTimeout(() => this.chromatogramDS.paginator = this.ch_paginator);
    setTimeout(() => {this.chromatogramDS.sort = this.ch_sort});
  }

  getUrl(analysisType: string) {
    if (analysisType === 'RSG') {
      return '/default-layout/analysis-details';
    } else if (['SMR', 'LMR', 'ADA', 'AP'].indexOf(analysisType) !== -1) {
      return '/default-layout/molecule-analysis';
    } else if (analysisType === 'CHR') {
      return '/default-layout/chromatograms';
    } else {
      return '/default-layout/notfound';
    }
  }



  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  applyFilterRSG(filterValue: string) {
    this.reportPopulationDS.filter = filterValue.trim().toLowerCase();
  }
  applyFilterCH(filterValue: string) {
    this.chromatogramDS.filter = filterValue.trim().toLowerCase();
  }

}
